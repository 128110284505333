<template>
  <div class="page-header-index-wide">
    <a-card :bordered="false" :bodyStyle="{ padding: '16px 0', height: '100%' }" :style="{ height: '100%' }"
            title="文章类型树">
      <a-button slot="extra" style="margin-left: 16px" type="primary" @click="treeAddClickHandle">
        <a-icon type="plus-circle"/>
        增加
      </a-button>
      <div class="account-settings-info-main">
        <div class="account-settings-info-right">
          <div class="panel">
            <div ref="treeContent">
              <div class="tree-panel">
                <z-tree ref="treeRef"
                        :load-url="treeLoadUrl"
                        @deleteClick="treeDeleteClickHandle"
                        @addClick="treeAddClickHandle"
                        @treeNodeClick="treeNodeClickHandle"
                        @dropNode="handleDrop"></z-tree>
              </div>
            </div>
            <div class=content-panel>
              <div class="detail-content-panel">
                <div class="panel-body">
                  <div class="panel-body-title">
                    <span>文章类型详情</span>
                  </div>
                  <article-type-form ref="editRef" @editSuccess="handleEditSuccess"></article-type-form>
                </div>
              </div>
            </div>
            <a-modal
              title="文章类型添加"
              v-model="visible"
              okText="添加"
              cancelText="取消"
              width="960px"
              :confirmLoading="addStatus"
              @ok="handleOk">
              <Article-type-form ref="addRef"
                                 type="add"
                                 @addSuccess="handleAddSuccess"
                                 @addError="handleAddError"
                                 @addStart="handleAddStart"
              ></Article-type-form>
            </a-modal>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import ZTree from '../../../components/ztree'
  import ArticleTypeForm from './ArticleTypeForm'

  export default {
    name: 'Type',
    data() {
      return {
        treeLoadUrl: SERVICE_URLS.article.type.tree,
        visible: false,
        addStatus: false
      }
    },
    components: { ArticleTypeForm, ZTree },
    methods: {
      handleDrop (dropNode, targetNode, moveType) {
        if (dropNode && targetNode && moveType) {
          this.$http(this, {
            url: SERVICE_URLS.article.type.moveNode,
            data: {
              dropNodeId: dropNode.id,
              targetNodeId: targetNode.id,
              moveType
            },
            successTipsContent: '拖拽更新成功',
            success: () => {
              this.$refs.treeRef.loadTree()
            }
          })
        }
      },
      treeNodeClickHandle(treeNode) {
        this.$nextTick(() => {
          this.$refs['editRef'].loadData(treeNode.id)
        })
      },
      treeDeleteClickHandle(treeNode) {
        this.showDeleteConfirm(treeNode)
      },
      treeAddClickHandle(treeNode) {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['addRef'].loadSelect(treeNode.id)
        })
      },
      handleOk() {
        this.$refs['addRef'].submitAdd()
      },
      handleAddStart() {
        this.addStatus = true
      },
      handleEditSuccess(value) {
        this.$refs['treeRef'].updateSelectNode(value)
      },
      handleAddSuccess(value) {
        this.$refs['treeRef'].addNode(value)
        this.visible = false
      },
      handleAddError() {
        this.addStatus = false
      },
      showDeleteConfirm(treeNode) {
        this.$confirm({
          title: '确定删除当前字典？',
          content: '会自动删除所有子节点，请谨慎操作！',
          okText: '删除',
          okType: 'danger',
          cancelText: '取消',
          onOk: () => {
            return new Promise((resolve, reject) => {
              this.deleteDelete(treeNode, resolve)
            }).catch(() => this.$error('Oops errors!'))
          }
        })
      },
      deleteDelete(treeNode, resolve) {
        this.$http(this, {
          url: SERVICE_URLS.article.type.delete,
          params: {
            id: treeNode.id
          },
          success: () => {
            this.$refs['treeRef'].deleteNode(treeNode)
            resolve()
          }
        })
      }
    }

  }
</script>

<style scoped>

</style>