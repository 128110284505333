<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="编号">
            {{formItem.id}}
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="全称">
            {{formItem.fullName}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="名称">
            <a-input
              v-decorator="['name']"
              placeholder="输入名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="上级节点">
            <a-select
              showSearch
              optionFilterProp="children"
              placeholder="请选择上级节点"
              v-decorator="['parentId']"
              style="width: 100%">
              <a-select-option :key="item.key"
                               :value="item.key"
                               :title="item.value"
                               v-for="item in options">
                {{item.value}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="设置是否状态（禁用后将不在栏目中显示）">
            <a-switch checkedChildren="启用" unCheckedChildren="禁用"
                      v-decorator="['status', {
                      rules:[{type:'boolean',required:true}],
                      trigger:'change',valuePropName:'checked',initialValue:true}]"></a-switch>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="排序">
            <a-input-number
              style="width: 100%"
              :step="0.1"
              v-decorator="['sorter', {rules:[
          {type:'number',required:true,message:'排序号必须为数字'}
          ],trigger:'change',initialValue:0}]"
              placeholder="输入排序号"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所有父节点ID信息" v-if="type === 'edit'">
            {{formItem.parentIds}}
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="最近更新时间" v-if="type === 'edit'">
            {{formItem.lastUpdated}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建时间" v-if="type === 'edit'">
            {{formItem.dateCreated}}
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建人（用户Username）" v-if="type === 'edit'">
            {{formItem.creater}}
          </a-form-item>
          <div style="width: 412px">
            <a-form-item v-if="type === 'edit'">
              <a-affix :offsetBottom="10">
                <a-button type="primary" style="float: right"
                          :loading="ifSub"
                          @click="submitEdit">保存修改
                </a-button>
              </a-affix>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>

  import SERVICE_URLS from '../../../api/service.url'
  import typeVO from '../../../api/vo/blog/typeVO'

  export default {
    name: 'ArticleTypeForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    computed: {
      onlyUnit () {
        const temp = []
        if (this.options) {
          this.options.forEach(x => {
            if (x.attribute === 'unit') {
              temp.push(x)
            }
          })
        }
        return temp
      }
    },
    data () {
      return {
        options: [],
        formItem: typeVO(),
        spinShow: false,
        form: this.$form.createForm(this),
        ifSub: false
      }
    },
    methods: {
      /*
       * 通过ID获取数据
       */
      loadData (id) {
        // this.formItem = typeVO()
        // this.form.resetFields()
        const self = this
        this.$http(self, {
          url: SERVICE_URLS.article.type.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body.select
            //一定要注意 不然会导致双向绑定选择框无法选中值
            this.$nextTick(() => {
              this.setFields(data.body.content)
            })
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.formItem.parentId = values.parentId
        this.form.setFieldsValue({
          name: this.formItem.name,
          parentId: this.formItem.parentId,
          sorter: this.formItem.sorter,
          status: this.formItem.status,
        })
      },
      setVOFields (values) {
        this.formItem.name = values.name
        this.formItem.parentId = values.parentId
        this.formItem.sorter = values.sorter
        this.formItem.status = values.status

      },
      /**
       * 加载下拉框数据，添加时使用
       * @param id 默认选中值
       */
      loadSelect (id) {
        this.$http(this, {
          url: SERVICE_URLS.article.type.select,
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body
            this.$nextTick(() => {
              this.form.setFieldsValue({
                parentId: id
              })
            })
          }
        })
      },
      submitEdit () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.ifsub = true
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.article.type.update,
                data: this.formItem,
                params: {
                  id: this.formItem.id
                },
                success: (data) => {
                  this.ifsub = false
                  this.setFields(data.body)
                  this.$emit('editSuccess', data.body)
                }
              })
            } else {
            }
          }
        )
      },
      submitAdd () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.article.type.create,
                data: this.formItem,
                success: (data) => {
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('addSuccess', data.body)
                  //清空表单数据
                  this.formItem = typeVO()
                  this.form.resetFields()
                },
                error: () => {
                  this.$emit('addError')
                }
              })
            }
          }
        )
      }
    }
  }
</script>