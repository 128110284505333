const typeVO = function () {
  return {
    id: '',
    name: '',
    fullName: '',
    parentId: null,
    parentIds: null,
    resourceId: 0,
    status: true,
    sorter: 0,
    creater: '',
    dateCreated: '',
    lastUpdated: ''
  }
}
export default typeVO
